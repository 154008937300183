import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import * as EducationRegistrationStyle from '../../components/css/educationRegistration.module.css'
const JobbaMedOss = () => (
  <Layout>
    <Seo title='Jobba med oss' />
    <PageHeader text='Jobba med oss' />

    <div className='container'>
      <div className='row' />

      <div className={` col-12 col-sm-12 col-md-12 col-lg-6 col-xl-12 ${EducationRegistrationStyle.form}`}>

        <h3>Är du en serviceinriktad och pedagogisk person?</h3>
        <p>Är du intresserad av att börja arbeta hos oss? Skriv ett PM till oss här via hemsidan
          så kontaktar/meddelar vi dig om framtida samarbeten.
        </p>

        <form name='Jobba på trafikskolan' method='POST' data-netlify='true' action='/info/tack'>
          <input type='hidden' name='form-name' value='Jobba på trafikskolan' />

          <div className='form-group'>
            <label htmlFor='namn'>Namn</label>
            <input type='text' className='form-control' placeholder='Namn' name='Namn' required />
          </div>

          <div className='form-group'>
            <label htmlFor='telefon'>Telefon</label>
            <input type='text' className='form-control' placeholder='Telefon' name='Telefon' required />
          </div>
          <div className='form-group'>
            <label htmlFor='e-postadress'>E-postadress</label>
            <input type='email' className='form-control' id='inputEmail' placeholder='E-postadress' name='E-postadress' required />
          </div>
          <div className='form-group'>
            <label htmlFor='textarea'>Ditt PM</label>
            <textarea className='form-control' id='textarea' rows='3' name='Övriga synpunkter' />
          </div>
          <button type='submit' className='btn btn-danger'>Skicka</button>
        </form>
      </div>

    </div>

  </Layout>
)

export default JobbaMedOss
